var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { KinesisClient, PutRecordCommand, PutRecordsCommand } from "@aws-sdk/client-kinesis";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { v4 as uuidv4 } from "uuid";
export default class Kinesis {
  constructor(streamName) {
    this.client = new KinesisClient(this._connectionParams());
    this.streamName = streamName;
    this.partitionKey = uuidv4();
  }
  send(data) {
    return __async(this, null, function* () {
      const enc = new TextEncoder();
      const command = new PutRecordCommand({
        Data: enc.encode(data),
        PartitionKey: this.partitionKey,
        StreamName: this.streamName
      });
      const response = yield this.client.send(command);
    });
  }
  send_bulk(records) {
    return __async(this, null, function* () {
      const enc = new TextEncoder();
      const kinesisRecords = records.map((e) => {
        return {
          Data: enc.encode(e),
          PartitionKey: this.partitionKey
        };
      });
      const command = new PutRecordsCommand({
        Records: kinesisRecords,
        StreamName: this.streamName
      });
      const response = yield this.client.send(command);
    });
  }
  _connectionParams() {
    const connectionParams = {
      region: "us-east-1"
    };
    connectionParams["credentials"] = fromCognitoIdentityPool({
      identityPoolId: ANALYTICS_KINESIS_IDENTITY_POOL_ID,
      clientConfig: {
        region: "us-east-1"
      }
    });
    return connectionParams;
  }
}
