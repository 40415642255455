var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Kinesis from "./kinesis";
import { v4 as uuidv4 } from "uuid";
import anonymousIdentity from "./anonymous-identity";
export default class TintEcommerceClient {
  constructor() {
    this.kinesis = new Kinesis("analytics-stream");
    this.database = "tint-analytics";
    this.table = "ecommerce_events";
  }
  track(name, params) {
    return __async(this, null, function* () {
      const user_id = anonymousIdentity();
      const properties = __spreadProps(__spreadValues({}, params), {
        uuid: user_id,
        id: uuidv4()
        // unique ID
      });
      if (window.TINT_LOG) {
        console.log(`tracking: ${name} - ${JSON.stringify(properties)}`);
      }
      return yield this.kinesis.send(JSON.stringify(this.trackEvent(name, params)));
    });
  }
  trackBulk(records) {
    return __async(this, null, function* () {
      const user_id = anonymousIdentity();
      let trackRecords = records.map((e) => {
        const event = __spreadProps(__spreadValues({}, e.params), {
          uuid: user_id,
          id: uuidv4()
        });
        const trackEvent = this.trackEvent(e.name, event);
        if (window.TINT_LOG) {
          console.log(`tracking bulk ${JSON.stringify(trackEvent)}`);
        }
        return trackEvent;
      });
      trackRecords = trackRecords.map((e) => JSON.stringify(e));
      return yield this.kinesis.send_bulk(trackRecords);
    });
  }
  trackEvent(name, params) {
    return {
      type: "track",
      event: name,
      database: this.database,
      table: this.table,
      properties: params,
      timestamp: (/* @__PURE__ */ new Date()).toISOString()
    };
  }
}
