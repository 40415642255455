import { Form } from "./form-builder";
import TintEcommerceMeasure from "./analytics/tint-ecommerce-measure";
const tintFormTagName = "tint-form";
const fixFormsWithDivTag = () => {
  const forms = document.querySelectorAll("[data-team-id][data-form-id]");
  if (forms) {
    forms.forEach((form) => {
      if (form.tagName === "DIV" || form.tagName === "DIALOG") {
        const tintForm = document.createElement(tintFormTagName);
        const formAttributes = Array.from(form.attributes);
        for (const attribute of formAttributes) {
          if (attribute.name.includes("data")) {
            const splitAttribute = attribute.name.split("data-");
            const extractedAttribute = splitAttribute == null ? void 0 : splitAttribute[1];
            tintForm.setAttribute(extractedAttribute, attribute.value);
          }
        }
        if (form.tagName === "DIALOG") {
          const dialog = document.createElement("dialog");
          if (form.open) {
            dialog.setAttribute("open", true);
          }
          dialog.appendChild(tintForm);
          form.replaceWith(dialog);
        } else {
          form.replaceWith(tintForm);
        }
      }
    });
  }
};
const init = () => {
  fixFormsWithDivTag();
  window.Tint = {
    TintEcommerceMeasure,
    WebComponents: {
      Form
    },
    formScriptFetched: false
  };
  customElements.define(tintFormTagName, window.Tint.WebComponents.Form);
};
if (typeof window !== "undefined") {
  if (window._env_) {
    init();
  } else {
    const script = document.createElement("script");
    script.src = `${new URL(document.currentScript.src).origin}/dist/env-config.js`;
    script.async = true;
    script.onload = init;
    document.head.appendChild(script);
  }
}
