var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import TintEcommerceClient from "./src/tint-ecommerce-client";
import { v4 as uuidv4 } from "uuid";
import { object, string, number, date, InferType } from "yup";
import Big from "big.js";
class TintEcommerceMeasure {
  constructor(teamId, init = true) {
    this.client = new TintEcommerceClient();
    this.teamId = teamId;
  }
  init(title = document.title, url = document.location.href) {
    this.client.track("ecommerce-page-loaded", {
      team_id: this.teamId,
      url,
      title
    });
  }
  addToCart(items, txId = uuidv4()) {
    this._validate(items);
    const events = items.map((item) => {
      return {
        name: "ecommerce-add-to-cart-item",
        params: __spreadProps(__spreadValues({}, item), {
          transaction_id: txId,
          team_id: this.teamId
        })
      };
    });
    this.client.trackBulk(events);
  }
  checkout(items, txId = uuidv4()) {
    this._validate(items);
    const events = items.map((item) => {
      return {
        name: "ecommerce-checkout-item",
        params: __spreadProps(__spreadValues({}, item), {
          transaction_id: txId,
          team_id: this.teamId
        })
      };
    });
    const consolidatedOrderAmount = items.reduce((total, item) => {
      return total + item.price;
    }, 0);
    const consolidatedOrderQuantity = items.reduce((total, item) => {
      return total.plus(new Big(item.quantity));
    }, new Big(0));
    const orderEvent = {
      name: "ecommerce-checkout-order",
      params: {
        transaction_id: txId,
        team_id: this.teamId,
        price: consolidatedOrderAmount.toString(),
        quantity: consolidatedOrderQuantity
      }
    };
    events.push(orderEvent);
    this.client.trackBulk(events);
  }
  _validate(items) {
    let orderItemSchema = object({
      price: number().required(),
      quantity: number().required(),
      sku: string().required(),
      currency: string().length(3).required()
    });
    items.map((item) => {
      orderItemSchema.validate(item);
    });
  }
}
window.Tint = window.Tint || {};
window.Tint.TintEcommerceMeasure = TintEcommerceMeasure;
export default TintEcommerceMeasure;
