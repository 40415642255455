var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
export const POST_SUBMIT_ACTION = {
  SHOW_SUCCESS_MESSAGE: "show_success_message",
  REDIRECT_TO_URL: "redirect_to_url",
  HIDE_THE_FORM: "hide_the_form"
};
export class Form extends HTMLElement {
  constructor() {
    super(...arguments);
    __publicField(this, "scriptUrl", `${window._env_.BASE_URL}dist/formBuilder-bundle.js`);
    __publicField(this, "stylesUrl", `${window._env_.BASE_URL}dist/formBuilder.css`);
    __publicField(this, "apiUrl", window._env_.API_URL);
    __publicField(this, "snareUrl", "https://mpsnare.iesnare.com/snare.js");
    __publicField(this, "formSelector", "[team-id][form-id]");
    __publicField(this, "forms", null);
    __publicField(this, "initialized", false);
    __publicField(this, "_getCookie", (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2)
        return parts.pop().split(";").shift();
    });
  }
  _getScript(scriptUrl) {
    return document.querySelector(`script[src='${scriptUrl}']`);
  }
  _includeFingerprint() {
    const snareScript = this._getScript(this.snareUrl);
    if (!snareScript) {
      window.io_bb_callback = (bb) => {
        window.bbDeviceFingerprint = bb;
      };
      const script = document.createElement("script");
      script.src = this.snareUrl;
      script.async = true;
      document.body.appendChild(script);
    }
  }
  _afterSubmitAction(e, data) {
    var _a, _b, _c, _d, _e;
    const url = (_b = (_a = data == null ? void 0 : data.layout) == null ? void 0 : _a.postSubmit) == null ? void 0 : _b.url;
    const action = (_d = (_c = data == null ? void 0 : data.layout) == null ? void 0 : _c.postSubmit) == null ? void 0 : _d.action;
    if (!action) {
      return;
    }
    switch (action) {
      case POST_SUBMIT_ACTION.REDIRECT_TO_URL:
        if (url) {
          window.top.location.href = url;
        } else {
          console.warn("Missing url in post submit action");
        }
        break;
      case POST_SUBMIT_ACTION.HIDE_THE_FORM: {
        if (((_e = this == null ? void 0 : this.parentNode) == null ? void 0 : _e.tagName) === "DIALOG") {
          this.parentNode.close();
        } else {
          this.style.display = "none";
        }
        break;
      }
    }
  }
  _renderFormOnScriptLoad(script) {
    script.addEventListener("load", () => {
      this._renderForm();
    });
  }
  _fetchReactFormScript() {
    const reactScript = this._getScript(this.scriptUrl);
    if (!reactScript) {
      const script = document.createElement("script");
      script.src = this.scriptUrl;
      script.async = true;
      this._renderFormOnScriptLoad(script);
      document.body.appendChild(script);
    } else {
      if (window.Tint.formScriptFetched) {
        this._renderForm();
      } else {
        this._renderFormOnScriptLoad(reactScript);
      }
    }
  }
  _findConfigByElementId(elementId) {
    return window.tintForms && window.tintForms[elementId] || {};
  }
  _renderForm() {
    var _a, _b;
    window.Tint.formScriptFetched = true;
    let {
      teamId: configTeamId = void 0,
      formId: configFormId = void 0,
      locale: configLocale = void 0,
      noTrack: configNoTrack = void 0,
      values: configFormValues = void 0,
      events: configEvents = void 0
    } = this._findConfigByElementId(this.id);
    let teamId = this.getAttribute("team-id") || configTeamId;
    let formId = this.getAttribute("form-id") || configFormId;
    let locale = this.getAttribute("form-locale") || configLocale || document.documentElement.lang;
    let noTrack = this.getAttribute("notrack") != void 0 || configNoTrack;
    let formValues = this.getAttribute("form-values") || configFormValues;
    let onAfterLoad = (configEvents == null ? void 0 : configEvents.onAfterLoad) || null;
    let onSubmitUser = (configEvents == null ? void 0 : configEvents.onAfterSubmit) || null;
    let onBeforeSubmit = (configEvents == null ? void 0 : configEvents.onBeforeSubmit) || null;
    let onSubmitFinal = function(customEvent, formData) {
      var _a2;
      if (onSubmitUser) {
        if (!(onSubmitUser == null ? void 0 : onSubmitUser(customEvent))) return;
      }
      (_a2 = this._afterSubmitAction) == null ? void 0 : _a2.call(this, customEvent, formData);
    }.bind(this);
    (_b = (_a = window.Tint) == null ? void 0 : _a.tintFormBuilderRender) == null ? void 0 : _b.call(_a, {
      element: this,
      stylesUrl: this.stylesUrl,
      teamId,
      formId,
      locale,
      noTrack,
      formValues,
      apiUrl: this.apiUrl,
      tintAnonymousUid: this._getCookie("tint-anonymous-uid"),
      onAfterSubmit: onSubmitFinal.bind(this),
      onAfterLoad: onAfterLoad == null ? void 0 : onAfterLoad.bind(this),
      onBeforeSubmit: onBeforeSubmit == null ? void 0 : onBeforeSubmit.bind(this)
    });
  }
  connectedCallback() {
    if (!this.initialized) {
      this.initialized = true;
      this.forms = document.querySelectorAll(this.formSelector);
      if (this.forms.length > 0) {
        this._includeFingerprint();
        this._fetchReactFormScript();
      }
    }
  }
}
